import { Navigation } from "../common/Navigation";
import { Home } from "./Home";

export function HomePage() {

  return (
    <>
      <Navigation/>
      <Home/>
    </>
  );
}
