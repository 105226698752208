import { Navigation } from "../common/Navigation";
import { Credentials } from "./Credentials";


export function CredentialsPage() {
  return (
    <>
      <Navigation/>
      <Credentials/>
    </>
  )
}
